import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import { AppContext } from '../context/app-context';
import { GET_STORE, GET_VENUE } from '../graphql/query';
import {
  HOME_PAGE_ROUTE,
  LIST_VENDORS_PAGE_ROUTE,
  STORES_LIST_ROUTE,
} from '../constants/routes';
import { Text } from '../elements/text';
import { Loader } from '../elements/loader';
import { Header } from '../components/header';
import { customTheme } from '../theme';
import { Image } from '../elements/image';
import { images } from '../constants/images';
import MenuItemsList from '../components/vendor-menu/items-list';
import { FloatingViewCartButton } from '../components/vendor-menu/floating-view-cart-button';
import { StoreWithDetails } from '../components/vendor-menu/types';
import { VenueTypes } from './home/home';
import { Venue } from '../types';
import { CustomButton } from '../elements/button';

const VendorMenu = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['patron_portal', 'common']);

  const { vendorID, storeID } = useParams();
  const {
    selectedVendor,
    venueType,
    store: selectedStore,
  } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const { data = { getStore: {} }, loading: getVendorLoading } = useQuery(
    GET_STORE,
    {
      onCompleted: data => {
        if (data && data?.getStore?.owner) {
          selectedStore?.setValue(data.getStore);
          selectedVendor?.setValue(data.getStore.owner);
        }
      },
      variables: {
        ownerID: vendorID,
        storeID: storeID,
      },
    },
  );

  const { loading: fetchingVenue, data: venueData } = useQuery(GET_VENUE, {
    variables: {
      id: appContext.stadium?.value,
    },
    onCompleted: data => {},
  });

  const venue: Venue = venueData?.getVenue;
  const isDisabled = venue?.isDisabled || venue?.isDeleted;

  const store: StoreWithDetails = data.getStore;

  useEffect(() => {
    if (store.isDisabled) {
      navigate(LIST_VENDORS_PAGE_ROUTE);
      const message = t('patron_portal.vendor.notAvailableMsg', {
        ns: 'patron_portal',
        tradingName: store.displayName,
      });
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  }, [store]);

  if (getVendorLoading) {
    return <Loader visible={true} />;
  }

  let backIconRoute = `/?id=${appContext.stadium?.value}`;
  if (venueType?.value === VenueTypes.HOTEL) {
    backIconRoute = STORES_LIST_ROUTE;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header
            backIconRoute={backIconRoute}
            middleComponent={
              <Typography
                fontFamily={customTheme.fontFamily}
                fontSize={18}
                fontWeight={600}
              >
                {store.displayName}
              </Typography>
            }
          />
        </Grid>

        {isDisabled && (
          <Grid item xs={12} paddingTop={`${20}px`}>
            <Box p={2} sx={{ textAlign: 'center' }} mt="10vh">
              <Text
                text={t('patron_portal.vendorListing.notOperatingMsg', {
                  ns: 'patron_portal',
                  venueName: venue.name,
                })}
                fontSize={16}
              />
            </Box>
            <Grid
              container
              item
              xs={12}
              lg={6}
              m="auto"
              p={2}
              paddingTop={`${40}px`}
            >
              <CustomButton
                style={{ marginTop: '40vh' }}
                title={t('common.home', { ns: 'common' })}
                onClick={() => navigate(HOME_PAGE_ROUTE)}
              />
            </Grid>
          </Grid>
        )}

        {!isDisabled && (
          <Grid container item md={8} margin="auto">
            <Grid item xs={12} mt={1}>
              <Image
                src={
                  store.storeFrontImageUrl ||
                  store.owner.imageUrl ||
                  images.VENDOR_DEFAULT_IMAGE
                }
              />
            </Grid>
            {vendorID && (
              <MenuItemsList
                menuID={store.menu.id}
                ownerID={vendorID}
                storeID={storeID}
              />
            )}
          </Grid>
        )}
      </Grid>
      {!isDisabled && <FloatingViewCartButton />}
    </>
  );
};

export default VendorMenu;
