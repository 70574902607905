import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../elements/button";
import { getPriceWithCurrency } from "../../utils/charges";
import { VIEW_CART_ROUTE } from "../../constants/routes";
import { t } from "i18next";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useQuery } from "@apollo/client";
import { GET_PRE_ORDER_SUMMARY } from "../../graphql/query";
import { CartContext } from "../../context/cart-context";
import { useContext } from "react";
import { AppContext } from "../../context/app-context";
import { PreOrderSummary, Pricing } from "../../types";

export const FloatingViewCartButton = () => {
    const navigateCart = useNavigate();
    const { cartItemsCount, cart } = useContext(CartContext);
    const { stadium, isSectionClickAndCollectEnabled, serviceMethod } =
        useContext(AppContext);
    const queryItems = Object.keys(cart).map(key => {
        return {
            itemID: cart[key].id,
            count: cart[key].count,
            storeID: cart[key].storeID,
            customisations: cart[key].customisations,
            serviceMethod: serviceMethod?.value
        };
    });

    const { data: getSummaryData, loading: preOrderSummaryLoading, refetch: fetchPreOrderSummary  } = useQuery(
        GET_PRE_ORDER_SUMMARY,
        {
            variables: {
                preOrderSummaryInput: {
                    items: queryItems,
                    venueId: stadium?.value,
                    serviceMethod: serviceMethod?.value
                },
            },
        },
    );

    const preOrderSummary =
        (getSummaryData?.getPreOrderSummary as PreOrderSummary) || {};
    const { pricing = {} } = preOrderSummary;
    const { subTotal } = pricing as Pricing;
    if (cartItemsCount <= 0) {
        return <></>
    }
    return (
        <Box
            style={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                left: 0,
                minWidth: '50%',
                padding: '16px',
                display: 'flex',
                justifyContent: 'center',
                background: 'linear-gradient(to top, #FFFF, #F5F5F5)',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                boxShadow: '0px 8px 32px 6px #F5F5F5',
            }}
        >
            <CustomButton
                title={t('patron_portal.label.viewCart', { ns: 'patron_portal' })}
                badgeTitle={cartItemsCount.toString()}
                rightTitle={getPriceWithCurrency(subTotal || 0)}
                icon={<ShoppingCartOutlinedIcon fontSize="small" />}
                onClick={() => {fetchPreOrderSummary(), navigateCart(VIEW_CART_ROUTE)}}
                loading={preOrderSummaryLoading}
            />
        </Box>
    );
};