import { Box, Dialog, Divider, Grid, Paper, Typography } from '@mui/material';
import { ReactElement, useContext } from 'react';
import { Image } from '../elements/image';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';
import { GET_VENDORS, GET_VENUE } from '../graphql/query';
import { VendorCard } from '../components/vendors';
import { Header } from '../components/header';
import { CartIcon } from '../components/cart-icon';
import { SeatInfo } from '../elements/seat-info';
import { HOME_PAGE_ROUTE } from '../constants/routes';
import { AppContext } from '../context/app-context';
import { Loader } from '../elements/loader';
import { customTheme } from '../theme';
import {
  Vendor,
  Section as VenueSection,
  Venue,
  CollectionPoint,
} from '../types';
import { useQueryHook } from '../hooks/use-query.hook';
import { CustomButton } from '../elements/button';
import { useQuery } from '@apollo/client';
import { OrderTypeStatusBar } from '../components/order-type-status';
import { Text } from '../elements/text';
import { images } from '../constants/images';
import { useTranslation } from 'react-i18next';
import { NetworkErrorPage } from './network-error';

export function VendorList(): ReactElement {
  const {
    section,
    row,
    seat,
    stadium,
    isSectionClickAndCollectEnabled,
    collectionPoint,
    serviceMethod,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const appContext= useContext(AppContext);

  const { t } = useTranslation(['patron_portal', 'common']);

  const { loading: fetchingVenue, data: venueData } = useQuery(GET_VENUE, {
    variables: {
      id: stadium?.value,
    },
    onCompleted: data => {},
  });

  const venue: Venue = venueData?.getVenue;
  const isDisabled = venue?.isDisabled || venue?.isDeleted;

  const {
    data = { getPublicVendors: [] },
    loading: fetchingVendors,
    fetch: fetchVendors,
  } = useQueryHook({
    withoutToken: true,
    query: GET_VENDORS,
    variables: {
      vendorQueryParams: {
        filter: {
          venueID: stadium?.value,
          sectionIDs: section?.value,
          isDisabled: false,
        },
        sort: {
          isOpen: -1,
        },
      },
    },
  });

  const vendors = data.getPublicVendors;

  const selectedSection = venue?.sections?.find(
    (_section: VenueSection) => _section?.id === section?.value,
  );

  const selectedCollectionPoint = venue?.collectionPoints?.find(
    (_collectionPoint: CollectionPoint) =>
      _collectionPoint?.id === collectionPoint?.value,
  );

  if (fetchingVendors || fetchingVenue) {
    return <Loader visible={fetchingVendors || fetchingVenue} />;
  }

  const vendorListImageSource = venue.vendorListImageUrl || '';
    const backIconRoute = `/?id=${appContext.stadium?.value}`;


  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ zIndex: 2 }}>
          <Header
            backIconRoute={backIconRoute}
            middleComponent={
              <SeatInfo
                stadium={venue?.name}
                section={selectedSection?.label}
                row={row?.value}
                seat={seat?.value}
                collectionPoint={selectedCollectionPoint?.label}
                isClickAndCollectEnabled={
                  isSectionClickAndCollectEnabled?.value
                }
                serviceMethod={serviceMethod?.value}
              />
            }
          />
        </Grid>

        <Grid item xs={12} sx={{ zIndex: 1 }}>
          <Box>
            <OrderTypeStatusBar
              serviceAvailable={true}
              venueName={venue.name}
              serviceMethod={serviceMethod?.value || ''}
            />
          </Box>
        </Grid>

        {vendorListImageSource && (
          <Box
            style={{
              width: '100%',
              maxHeight: '25vh',
              paddingTop: '12px',
            }}
          >
            <Box
              style={{
                overflow: 'hidden',
              }}
            >
              <Image
                src={vendorListImageSource}
                style={{
                  objectFit: 'contain',
                  maxHeight: '25vh',
                }}
              />
            </Box>
          </Box>
        )}

        {isDisabled && (
          <Grid item xs={12} paddingTop={`${20}px`}>
            <Box p={2} sx={{ textAlign: 'center' }} mt="10vh">
              <Text
                text={t('patron_portal.vendorListing.notOperatingMsg', {
                  ns: 'patron_portal',
                  venueName: venue.name,
                })}
                fontSize={16}
              />
            </Box>
            <Grid
              container
              item
              xs={12}
              lg={6}
              m="auto"
              p={2}
              paddingTop={`${40}px`}
            >
              <CustomButton
                style={{ marginTop: '40vh' }}
                title={t('common.home', { ns: 'common' })}
                onClick={() => navigate(HOME_PAGE_ROUTE)}
              />
            </Grid>
          </Grid>
        )}

        {!isDisabled && (
          <Grid container item xs={12} lg={10} m="auto" px={2}>
            <Grid item mt={2} mb={2} xs={12}>
              <Text
                text={t('patron_portal.label.vendor', { ns: 'patron_portal' })}
                fontSize={18}
                fontWeight={700}
              />
            </Grid>
            <ListVendors vendors={vendors.slice()} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

const ListVendors = (props: { vendors: Vendor[] }) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  if (props.vendors.length === 0) {
    return (
      <Grid item xs={12}>
        <Box sx={{ textAlign: 'center' }} mt={'40vh'}>
          <Text
            text={t('patron_portal.vendorListing.vendorNotAvailableMsg', {
              ns: 'patron_portal',
            })}
          />
        </Box>
      </Grid>
    );
  }

  return (
    <Grid container spacing={{ sm: 2 }}>
      {props.vendors.sort((v1, v2) => {
          if (!process.env.REACT_APP_TOP_VENDOR_NAME) {
            return 0;
          }
          if (v1?.tradingName.trim() === process.env.REACT_APP_TOP_VENDOR_NAME) {
            return -1;
          }
          if (v2?.tradingName.trim() === process.env.REACT_APP_TOP_VENDOR_NAME) {
            return 1;
          }
          return 0;
        })
      .map((vendor: Vendor, index: number) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={`${vendor._id}-${index}`}
          margin={'auto'}
        >
          <VendorCard {...vendor} />
          <Divider
            sx={{
              marginBottom: '16px',
              borderColor: 'black',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
