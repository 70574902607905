import { Alert, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { IOModelVenueWithStores } from '../../types/io-models';
import {
  IVenueSectionDeliveryInfoMeta,
  VenueSeatChoice,
} from '../../pages/home/home';
import { ServiceMethod } from '../../types/order';
import { StoreWithDetails } from '../vendor-menu/types';
import { CustomSelect } from '../../elements/select';
import { useTranslation } from 'react-i18next';
import { CustomInput } from '../../elements/input';
import { CustomButton } from '../../elements/button';
import { DeliveryArea, Section } from '../../types';
import { useSnackbar } from 'notistack';
import { LocationDetailsModal } from '../location-details-modal';
import { useSearchParams } from 'react-router-dom';

export const TableServiceInfoForm: React.FC<{
  venue: IOModelVenueWithStores;
  patronVenueChoices: VenueSeatChoice | undefined;
  refetchVenue: () => void;
  serviceAvailable: boolean;
  onLocationSelection: (
    selectedServiceMethod: ServiceMethod | undefined,
    deliveryLocationInfo: IVenueSectionDeliveryInfoMeta,
    selectedStore?: StoreWithDetails,
  ) => void;
}> = ({
  venue,
  patronVenueChoices,
  refetchVenue,
  onLocationSelection,
  serviceAvailable,
}) => {
  const [searchParams] = useSearchParams();
  const scannedQR = searchParams.get('qr');
  const tableNumberFromParams = searchParams.get('tableNumber');

  const { t } = useTranslation(['patron_portal', 'common']);
  const { enqueueSnackbar } = useSnackbar();

  const [isValidInfo, setInfoValidity] = useState(false);
  const [updatedVenue, setUpdatedVenue] =
    useState<IOModelVenueWithStores | null>(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const [selectedSectionID, setSection] = useState<string | undefined>(
    patronVenueChoices?.serviceChoices?.deliveryInfo?.sectionID ?? undefined,
  );
  const [tableNo, setTableNo] = useState<string>(
    patronVenueChoices?.serviceChoices?.deliveryInfo?.seat ?? '',
  );
  const deliveryAreaSections: DeliveryArea[] = venue?.deliveryAreas || [];
  const [deliveryArea, setDeliveryArea] = useState<string>('');
  const venueStores: IOModelVenueWithStores['sellingStores'] =
    venue?.sellingStores;
  const sections: Section[] =
    venue?.sections?.filter((section: Section) => !section.isDeleted) || [];
  const selectedSection = sections.find(sec => sec.id === selectedSectionID);

  // FIXME: Tech Debt Alert! Looking at section label to decide weather to show table input
  const showTableNoInput = () => {
    return selectedSection?.label.toUpperCase() === 'DINE IN' ? true : false;
  };
  const selectedServiceMethod = selectedSection?.serviceMethods[0];
  const showDeliveryAreaInput = () => {
    return selectedSection?.serviceMethods.includes(
      ServiceMethod.VENDOR_DELIVERY,
    );
  };

  // Auto-fill on mount if QR is used
  useEffect(() => {
    if (scannedQR?.toUpperCase() !== 'TRUE') return;
    const dineInSection = sections.find(
      section => section.label.toUpperCase() === 'DINE IN',
    );
    setSection(dineInSection?.id);
    setTableNo(tableNumberFromParams || '');
  }, []);

  useEffect(() => {
    if (
      (showTableNoInput() &&
        tableNo &&
        tableNo.trim() != '' &&
        selectedSectionID) ||
      (!showTableNoInput() && selectedSectionID && serviceAvailable)
    ) {
      setInfoValidity(true);
    } else {
      setInfoValidity(false);
    }
  }),
    [tableNo, selectedSectionID];

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const allStoresClosed = venueStores.every(store => !store.isOpen);
      if (allStoresClosed) {
        const updatedVenue: any = await refetchVenue();
        setUpdatedVenue(updatedVenue.data.getVenue);
      } else {
        clearInterval(intervalId);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [refetchVenue, venueStores]);

  const handleContinue = () => {
    const selectedSection = sections.find(sec => sec.id === selectedSectionID);
    const currentVenue = updatedVenue || venue;
    const stores = currentVenue?.sellingStores;
    const openStore = stores?.find(store => store.isOpen && store.menu);

    onLocationSelection(
      selectedServiceMethod,
      {
        collectionPoint: undefined,
        row: 'N/A',
        seat: showTableNoInput() ? tableNo : '0',
        section: selectedSection?.id,
      },
      openStore,
    );
    handleCloseModal();
  };

  const goToNext = async () => {
    const currentVenue = updatedVenue || venue;
    const stores = currentVenue?.sellingStores;

    const openStore = stores?.find(store => store.isOpen && store.menu);
    if (!openStore) {
      enqueueSnackbar('Store is currently closed or unavailable.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      return;
    }

    if (selectedServiceMethod === ServiceMethod.VENDOR_DELIVERY) {
      handleOpenModal();
    } else {
      handleContinue();
    }
  };

  return (
    <Stack>
      <CustomSelect
        onChange={(value: string) => {
          setSection(value);
        }}
        data={sections}
        title={t(
          'patron_portal.seatSelectionForm.preferredServiceMethodLabel',
          {
            ns: 'patron_portal',
          },
        )}
        value={selectedSectionID}
      />

      {showTableNoInput() && (
        <CustomInput
          onChange={setTableNo}
          title={t('common.tableNo', { ns: 'common' })}
          value={tableNo}
        />
      )}

      {showDeliveryAreaInput() && (
        <CustomSelect
          onChange={(value: string) => {
            setDeliveryArea(value);
          }}
          data={deliveryAreaSections}
          title={t('common.deliveryArea', { ns: 'common' })}
          value={deliveryArea}
        />
      )}

      <CustomButton
        disabled={!isValidInfo || (showDeliveryAreaInput() && !deliveryArea)}
        title={t('patron_portal.storeSelectionForm.submitButtonLabel', {
          ns: 'patron_portal',
        })}
        onClick={goToNext}
        style={{
          marginTop: '16px',
          color: 'white',
          border: '1px solid white',
          boxShadow: `0px 0px 0px 6px ${isValidInfo ? '#a18146' : '#735c31'}`,
          background: `${isValidInfo ? '#a18146' : '#735c31'}`,
          p: '8px',
          '&:hover': {
            background: '#735c31',
            border: '1px solid white',
            boxShadow: '0px 0px 0px 6px #735c31',
          },
          '&:focus': {
            border: '1px solid white',
          },
        }}
      />
      <LocationDetailsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSaveAndContinue={handleContinue}
      />
    </Stack>
  );
};
